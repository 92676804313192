<template>
  <CRow>
    <CCol col="12">
      <Toast ref="mensajeToast"></Toast>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Alerts ref="alert"></Alerts>
      <div class="card border-warning">
        <div class="card-header">
          <strong class="text-primary">
            Reporte de Facturación (de Estudiantes y Clientes Externos)
          </strong>
        </div>
        <div class="card-body p-2">
          <div class="row">
            <div class="col"></div>
            <div class="col">
              <label class="form-label" for="fecha_ini">Fecha Inicial:</label>
              <input id="fecha_ini" v-model="reporte.fecha_ini"
                     class="form-control text-center" required type="date"/>
            </div>
            <div class="col">
              <label class="form-label" for="fecha_fin">Fecha Final:</label>
              <input id="fecha_fin" v-model="reporte.fecha_fin"
                     class="form-control text-center" required type="date"/>
            </div>
            <div class="col">
              <label class="form-label" for="fecha_fin">Tipo Factura:</label>
              <select class="form-control mr-2 ml-1" v-model="reporte.tipofactura">
                <option value="T">:: TODOS ::</option>
                <optgroup label="Facturación En Línea">
                  <option value="FLI">Estudiantes</option>
                  <option value="FLE">Clientes Externos</option>
                </optgroup>
<!--                <optgroup label="Facturación manual">-->
<!--                  <option value="M">Estudiantes</option>-->
<!--                  <option value="ME">Clientes Externos</option>-->
<!--                </optgroup>-->
<!--                <optgroup label="Facturación electrónica">-->
<!--                  <option value="I">Estudiantes</option>-->
<!--                  <option value="E">Clientes Externos</option>-->
<!--                </optgroup>-->
              </select>
            </div>
            <div class="col">
              <CSelect
                  id="usuario"
                  :options="usuarios"
                  :plain="true"
                  :value.sync="reporte.usuario"
                  label="Usuario:"
              >
              </CSelect>
            </div>
            <div class="col"></div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
          <div class="col-6 text-center">
            <h5>Formato Vertical</h5>
            <button class="mr-2 btn btn-outline-danger" @click="reporteVertical()">
              <font-awesome-icon icon="fa-regular fa-file-pdf"/>Generar Reporte PDF
            </button>
            <button class="mr-2 btn btn-outline-success" @click="reporteVerticalXlsx()">
              <font-awesome-icon icon="fa-file-excel"/>
              Generar Reporte Excel</button>
          </div>
          <div class="col-6 text-center">
            <h5>Formato Horizontal</h5>
            <button class="mr-2 btn btn-outline-danger" @click="reporteHorizontal()">
              <font-awesome-icon icon="fa-regular fa-file-pdf"/>Generar Reporte PDF
            </button>
            <button class="mr-2 btn btn-outline-success" @click="reporteHorizontalXlsx()">
              <font-awesome-icon icon="fa-file-excel"/>
              Generar Reporte Excel</button>
          </div>
          </div>
        </div>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import ModalPDF from "@/views/notifications/ModalPDF";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";

var moment = require('moment');
var modalPdf;

export default {
  name: 'EditUser',
  components: {Alerts, Toast, ModalPDF},
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      darkModal: false,
      reporte: {
        fecha_ini: '',
        fecha_fin: '',
        tipofactura: 'T',
        usuario: '',
        sede_id: 0,
      },
      usuarios: [],
      maxdate: moment(new Date()).format("YYYY-MM-DD"),
      message: '',
      dismissSecs: 15,
      dismissCountDown: 0,
      showDismissibleAlert: false,
    }
  },
  beforeCreate: function () {

  },
  methods: {
    reporteVertical() {
      modalPdf.$refs.alert.show("Descargando documento");
      axios.post(this.$apiAdress + '/api/factura/reportearqueocaja?token=' + localStorage.getItem("api_token"),
          modalPdf.reporte, {responseType: 'blob'}
      )
          .then((response) => {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
          })
          .catch(function (error) {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    reporteVerticalXlsx: function () {
      modalPdf.$refs.alert.show("Generando Reporte");
      axios.post(this.$apiAdress + '/api/factura/reportearqueocajaxlsx?token=' + localStorage.getItem("api_token"),
          modalPdf.reporte, {responseType: 'blob'}
      )
          .then((response) => {
            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
                'download',
                'ReporteArqueoCaja-' + (new Date().toLocaleDateString()) + '.xlsx'
            )
            document.body.appendChild(link)
            link.click()
            modalPdf.$refs.alert.hide();
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error,modalPdf);
          });
    },
    reporteHorizontal() {
      modalPdf.$refs.alert.show("Descargando documento");
      axios.post(this.$apiAdress + '/api/factura/reportearqueocajahorizontal?token=' + localStorage.getItem("api_token"),
          modalPdf.reporte, {responseType: 'blob'}
      )
          .then((response) => {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
          })
          .catch(function (error) {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    reporteHorizontalXlsx: function () {
      modalPdf.$refs.alert.show("Generando Reporte");
      axios.post(this.$apiAdress + '/api/factura/reportearqueocajahorizontalxlsx?token=' + localStorage.getItem("api_token"),
          modalPdf.reporte, {responseType: 'blob'}
      )
          .then((response) => {
            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
                'download',
                'ReporteArqueoCajaHorizontal-' + (new Date().toLocaleDateString()) + '.xlsx'
            )
            document.body.appendChild(link)
            link.click()
            modalPdf.$refs.alert.hide();
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error,modalPdf);
          });
    },
  },
  mounted: function () {
    modalPdf = this;
    axios.get(this.$apiAdress + '/api/factura/' + localStorage.sedeSeleccionada + '/usuarios?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          let valorPorDefecto = [{value: '0', label: ':: TODOS ::'}];
          modalPdf.usuarios = valorPorDefecto.concat(response.data.usuarios);
          //modalPdf.usuarios = response.data.usuarios;
          modalPdf.reporte.usuario = response.data.usuario_id;
          modalPdf.reporte.fecha_ini = response.data.fecha;
          modalPdf.reporte.fecha_fin = response.data.fecha;
          modalPdf.reporte.sede_id = localStorage.sedeSeleccionada;
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  }
}
</script>